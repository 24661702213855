import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Trash from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_CONTROL_GROUP } from '../../graphql';
import { useSnackBars } from '../../hooks';

const DeleteControlGroupButton = ({ companyId, mailParameterId }) => {
  const { addAlert } = useSnackBars();

  const [deleteControlGroup, { loading }] = useMutation(
    DELETE_CONTROL_GROUP,
    {
      variables: { companyId, mailParameterId },
      onCompleted: () => {
        addAlert({
          id: 'delete-control-group',
          message: 'Asignación especial eliminada',
        });
      },
      onError: (error) => {
        addAlert({
          id: 'delete-control-group-error',
          message: { error },
          severity: 'error',
        });
      },
    },
  );

  return (
    <>
      <Tooltip title="Asignar plantilla default">
        <IconButton
          variant="contained"
          size="small"
          onClick={() => deleteControlGroup()}
          disabled={loading}
        >
          <Trash />
        </IconButton>
      </Tooltip>
    </>
  );
};

DeleteControlGroupButton.propTypes = {
  companyId: PropTypes.string.isRequired,
  mailParameterId: PropTypes.string.isRequired,
};

export default DeleteControlGroupButton;
