import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Typography, Stack, Card, CardContent, Tabs, Tab } from '@mui/material';
import { formatMoney } from '../../helpers';
import BusinessProfileHeaderField from './businessProfileHeaderField';

const BusinessProfileHeader = (
  { selectedProfile,
    selectedTab,
    setSelectedTab,
    availableOptions,
    selectedProfileId,
    setSelectedProfileId,
  },
) => (
  <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={2}>
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Stack direction="column" spacing={1.2}>
            <BusinessProfileHeaderField
              subtitle="Nombre"
              title={selectedProfile?.businessName}
            />
            <BusinessProfileHeaderField
              subtitle="Actividad"
              title={selectedProfile?.activity}
            />
            <BusinessProfileHeaderField
              subtitle="Act. Principal"
              title={selectedProfile?.economicActivity}
            />
            <BusinessProfileHeaderField
              subtitle="Subactividad"
              title={selectedProfile?.subActivity}
            />
            <BusinessProfileHeaderField
              subtitle="Dirección"
              title={selectedProfile?.address}
            />
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Stack direction="column" spacing={1.2}>
            <BusinessProfileHeaderField
              subtitle="Tipo"
              title={selectedProfile?.companyType}
            />
            <BusinessProfileHeaderField
              subtitle="Subtipo"
              title={selectedProfile?.companySubtype}
            />
            <BusinessProfileHeaderField
              subtitle="Tramo Ventas"
              title={selectedProfile?.salesSection}
            />
            <BusinessProfileHeaderField
              subtitle="N. Trabajadores"
              title={selectedProfile?.numberWorkers}
            />
            <BusinessProfileHeaderField
              subtitle="Dirección"
              title={selectedProfile?.address}
            />
            <BusinessProfileHeaderField
              subtitle="Año Incorporación"
              title={selectedProfile?.year}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
    <Stack direction="row" spacing={2}>
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Stack direction="column" spacing={1.2}>
            <BusinessProfileHeaderField
              subtitle="Apreciación Total Vechículos"
              title={`$${formatMoney(selectedProfile?.vehiclesAppraisal)}`}
            />
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Stack direction="column" spacing={1.2}>
            <BusinessProfileHeaderField
              subtitle="Apreciación Total Avalúo Fiscal"
              title={`$${formatMoney(selectedProfile?.taxAppraisalAmount)}`}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
    <Stack direction="row" spacing={6} justifyContent="center">
      <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)} centered>
        <Tab label="Sociedades" value="societies" />
        <Tab label="Accionistas" value="shareholders" />
        <Tab label="Vehiculos" value="vehicles" />
        <Tab label="Causas Judiciales" value="judicialDetails" />
        <Tab label="Avalúo Fiscal" value="taxAppraisal" />
      </Tabs>
      <Stack spacing={0.5} alignItems="center">
        <Typography><b>Fecha de reporte</b></Typography>
        <Select
          id="report-selector"
          value={selectedProfileId}
          onChange={(e) => setSelectedProfileId(e.target.value)}
          variant="outlined"
          placeholder="Seleccione un reporte"
        >
          {availableOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  </Stack>
);

BusinessProfileHeader.propTypes = {
  selectedProfileId: PropTypes.string,
  setSelectedProfileId: PropTypes.func.isRequired,
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
  })),
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selectedProfile: PropTypes.shape({
    id: PropTypes.string,
    businessName: PropTypes.string,
    economicActivity: PropTypes.string,
    subActivity: PropTypes.string,
    activity: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    salesSection: PropTypes.string,
    numberWorkers: PropTypes.string,
    companySubtype: PropTypes.string,
    year: PropTypes.number,
    vehiclesAppraisal: PropTypes.number,
    taxAppraisalAmount: PropTypes.number,
  }),
};

BusinessProfileHeader.defaultProps = {
  selectedProfileId: '',
  availableOptions: [],
  selectedProfile: {},
};

export default BusinessProfileHeader;
