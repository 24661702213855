import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { JudicialDetailColumns } from '../../constants';

const JudicialDetail = ({ dicomModel }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{ marginTop: 5 }}
  >
    <FingoDataGrid
      rows={dicomModel?.judicialDetails ?? []}
      columns={JudicialDetailColumns}
      serverFilters={false}
      hideFooter
      sx={{ minHeight: 250 }}
    />
  </Stack>
);

JudicialDetail.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    judicialDetails: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      type: PropTypes.string,
      creditor: PropTypes.string,
      clasification: PropTypes.string,
      debtDays: PropTypes.number,
      creditorDig: PropTypes.string,
      date: PropTypes.string,
      aclDate: PropTypes.string,
      source: PropTypes.string,
      glscodacteco: PropTypes.string,
      identification: PropTypes.string,
      process: PropTypes.string,
      reference: PropTypes.string,
      creditorRut: PropTypes.string,
      url: PropTypes.string,
    })),
  }),
};

JudicialDetail.defaultProps = {
  dicomModel: {},
};

export default JudicialDetail;
