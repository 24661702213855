import { Badge, IconButton, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import PropTypes from 'prop-types';

const ClearListButton = ({ setList, selectedInvoiceIds }) => {
  const clearSelection = useCallback(() => setList([]), []);
  return (
    <Tooltip title="Borrar selección">
      <Badge badgeContent={selectedInvoiceIds?.length} color="primary">
        <IconButton
          size="medium"
          onClick={clearSelection}
          color={selectedInvoiceIds?.length ? 'primary' : 'inherit'}
        >
          <ClearAllIcon />
        </IconButton>
      </Badge>
    </Tooltip>
  );
};

ClearListButton.propTypes = {
  setList: PropTypes.func.isRequired,
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string),
};

ClearListButton.defaultProps = {
  selectedInvoiceIds: [],
};

export default ClearListButton;
