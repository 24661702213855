import React from 'react';
import { IconButton, Typography } from '@mui/material';
import DrawerHeader from '../drawer/DrawerHeader';
import { ActionDrawerCell } from '../cells';
import { InvoiceType } from '../../propTypes';
import CollectionActions from '../drawer/CollectionActions';

const RatificationManagerActionButton = ({ invoice }) => (
  <IconButton
    disabled={invoice.ratificationmanager === null}
  >
    <ActionDrawerCell
      title={`Gestiones de ratificación factura ${invoice.folio}`}
      actions={invoice.ratificationmanager?.actions ?? []}
      headerComponent={DrawerHeader}
      contentComponent={CollectionActions}
      drawerId={invoice.id}
    />
    <Typography variant="h2" sx={{ ml: '5px', display: { xs: 'none', sm: 'inline', md: 'inline', lg: 'inline' } }}>Ratificación</Typography>
  </IconButton>

);

RatificationManagerActionButton.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default RatificationManagerActionButton;
