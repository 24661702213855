import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const UploadFileButton = ({ setFile, children, ...props }) => {
  const handleChange = useCallback((event) => {
    setFile(event.target.files[0]);
  }, []);

  return (
    <Button
      {...props}
      component="label"
    >
      {children}
      <input type="file" hidden onChange={handleChange} />
    </Button>
  );
};

UploadFileButton.propTypes = {
  setFile: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default UploadFileButton;
