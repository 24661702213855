import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack } from '@mui/material';

const BusinessProfileHeaderField = ({ subtitle, title }) => (
  <Stack direction="column">
    <Typography>
      {subtitle}
    </Typography>
    <Typography sx={{ fontSize: 14 }} variant="h6">
      <b>{title}</b>
    </Typography>
  </Stack>
);

BusinessProfileHeaderField.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BusinessProfileHeaderField;
